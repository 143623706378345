/* FILE: src/components/TZFileUploadV2.module.css */

/* Container styling */
.container {
  /* Important for the overlay to be confined to this container */
  position: relative;

  max-width: 600px;
  margin: 2rem auto;
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0,0,0,0.05);
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.title {
  color: #333;
  font-size: 1.75rem;
  margin-bottom: 0.1rem;
  text-align: center;
  font-weight: 600;
}

.instructions {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 1rem;
  text-align: center;
}

.controlsRow {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.monthSelector {
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.monthSelector label {
  font-weight: 500;
  color: #444;
  margin-bottom: 0.3rem;
  font-size: 0.85rem;
}

.e-datepicker,
.e-control-wrapper {
  width: 100% !important;
}

.uploadSection {
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
}

.dragArea {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 2rem 1rem;
  cursor: pointer;
  transition: border-color 0.3s;
  color: #666;
  font-weight: 500;
  font-size: 0.95rem;
  background: #fafafa;
  width: 100%;
}

.dragArea:hover {
  border-color: #999;
}

.fileInput {
  display: none;
}

.errorMessage {
  margin-top: 0.5rem;
  color: #d9534f;
  font-weight: 600;
}

.filePreview {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 0.8rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: left;
}

.filePreview h3 {
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 0.4rem;
}

.buttonContainer {
  text-align: center;
  margin-bottom: 1rem;
}

.uploadButton {
  background-color: #34a853;
  color: #fff;
  padding: 8px 14px; /* smaller than before to reduce height */
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  font-size: 0.95rem; /* slightly smaller */
  line-height: 1.2;  /* reduce vertical spacing */
}

.uploadButton:hover {
  background-color: #2c8e44;
}

.resultSection {
  background: #f1f9f3;
  border: 1px solid #cce5cc;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  margin-top: 1rem;
  text-align: center;
}

.resultSection h3 {
  margin: 0 0 0.3rem 0;
  color: #2c8e44;
  font-size: 1.1rem;
  font-weight: 600;
}

.resultSection p {
  margin: 0;
  font-size: 0.9rem;
}

.recentUpload {
  text-align: center;
  font-size: 0.65rem;
  margin: 1px 0;
}

/* Totzone Emails */
.emailContainer {
  margin-top: 30px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.emailTitle {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.emailSubTitle {
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.emailButtons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.emailButton {
  /* Reduced padding to make them shorter */
  padding: 8px 14px;
  font-size: 0.95rem;
  line-height: 1.2;
  
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.2s ease;
}

.emailButton:hover {
  background-color: #0056b3;
}

.emailButton:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.8;
}

/* "Construction tape" style for a disabled button */
.underDevelopmentDisabled {
  /* Use a repeating linear gradient with black/yellow stripes */
  background: repeating-linear-gradient(
    45deg,
    #000 0 10px,
    #ffd700 10px 20px
  );

  /* Adjust padding & font to reduce height further if needed */
  padding: 6px 12px;
  font-size: 0.9rem;
  line-height: 1.2;
  
  color: #fff; /* White text stands out on black/yellow stripes */
  cursor: not-allowed;
  opacity: 1; /* Make sure it's fully opaque */
  border: 2px solid #000; /* Like caution tape's black borders */
  font-weight: bold;
  text-transform: uppercase;
}

/* 
   The overlay covers only the container because 
   .container has position: relative
*/
.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* Sit above the container content */
  z-index: 999;

  /* We use a pseudo-element to dim the background */
  display: block;
}

.loadingOverlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.05);
}

/* The spinner remains bright on top of the dim */
.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
