/* MyScheduler.css */

/* Force Month rows to auto-expand (unchanged) */
.noMoreIndicator .e-schedule .e-month-view .e-content-table {
  height: auto !important;
  overflow: visible !important;
}

.e-more-appointment-popup.e-popup {
  width: 600px !important;
}

.e-more-popup-wrapper.e-popup .e-appointment {
  white-space: normal !important;
  line-height: 1.1em !important;
  padding: 3px !important;
  min-height: 35px !important;
}

/* Resource label margin (unchanged) */
.my-resource-label {
  margin-left: 1.7rem !important;
}

/* -----------------------------------------------------
   KEYFRAMES:
   - 0%: The highlight starts off to the far left (−300%).
   - 50%: It moves across to the far right (+300%).
   - 100%: It returns back to the far left (−300%).
   This gives a continuous back-and-forth sweep.
----------------------------------------------------- */
@keyframes goldShimmer {
  0% {
    transform: translateX(-300%) rotate(15deg);
  }
  50% {
    transform: translateX(300%) rotate(15deg);
  }
  100% {
    transform: translateX(-300%) rotate(15deg);
  }
}

/* -----------------------------------------------------
   DIRECTOR EVENT STYLING
----------------------------------------------------- */
.director-event {
  position: relative; /* So the pseudo-element is positioned inside it */
  overflow: hidden;   /* Hide the shimmer outside the event's bounds */

  /* Subtler gold gradient */
  background: linear-gradient(135deg, #ffd700, #ffe140) !important;
  color: #444 !important;
  border: 1px solid #f5c944 !important;
  border-radius: 4px !important;

  /* Make the glow smaller for less distraction */
  box-shadow: 0 0 3px 1px rgba(255, 215, 0, 0.4) !important;
}

/* The diagonal “highlight” that sweeps across the bar */
.director-event::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%; 
  width: 100%;
  height: 100%;
  pointer-events: none; /* so it doesn't block clicks */

  /* A narrower, lower-alpha highlight band */
  background: linear-gradient(
    75deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 60%
  );

  transform: rotate(15deg);

  /* 8-second cycle, going left→right (first 4s) 
     then right→left (next 4s), repeated infinitely */
  animation: goldShimmer 8s linear infinite;
}

/* Base styling for resource headers */
.my-resource-label {
  display: flex;
  align-items: center;
  padding: 4px;
}

/* Additional styling for director rows */
.director-row {
  border-bottom: 2px solid gold !important;
  box-shadow: 0 0 8px gold;
  background-color: #fffbe6; /* Optional: a light gold background for extra emphasis */
}