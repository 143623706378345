/* File: src/components/ProlifiField/wsn/director/SupervisorWeeklyStoreNotesModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
  padding: 20px;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 99%;
  height: 99%;
  max-width: 99%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 2000000;
  overflow: auto;
}

.modal-header {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.5em;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
}

.supervisor-navigation {
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.supervisor-name {
  font-size: 1.1em;
  font-weight: 600;
}

.nav-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s ease;
}

.nav-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.modal-body {
  padding: 16px 20px;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fafafa;
}

.store-notes {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
}

.store-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.store-name {
  font-size: 1.4em;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.page-indicator {
  text-align: center;
  font-size: 0.9em;
  color: #555;
  margin-bottom: 12px;
}

.store-details {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
}

.info-section {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  flex: 1 1 45%;
  min-width: 200px;
}

.info-title {
  font-weight: bold;
  margin-bottom: 4px;
  color: #333;
}

.info-content {
  margin: 0;
  color: #555;
}

.employee-funds {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mit-cards {
  margin-top: 12px;
}

.mit-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  background-color: #e9ecef;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.mit-name-col,
.mit-rating-col {
  font-weight: bold;
  font-size: 1em;
  flex: 1;
  text-align: center;
}

.mit-card {
  background-color: #fefefe;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.mit-card-row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 6px;
}

.mit-card-row .mit-name-col,
.mit-card-row .mit-rating-col {
  flex: 1;
  text-align: center;
}

.mit-card-details .info-section {
  margin-bottom: 6px;
}

.mit-photo-container {
  text-align: center;
  margin-top: 8px;
}

.mit-photo {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.rating-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  height: 12px;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.2);
}

.rating-fill {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
  background: /* dynamic gradient set inline */;
}

.rating-indicators {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.tick {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #666;
}

.status-message {
  text-align: center;
  font-size: 1em;
  color: #333;
}

.error {
  color: #d9534f;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}
